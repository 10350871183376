import React, {useState} from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router";
import axios from 'axios';
import styles from './Signup.module.scss';
import DialogBox from '../../components/DialogBox/DialogBox';
import Loader from "react-loader-spinner";


const Signup = () => {
    const firebase = useFirebase();
    const firestore = useFirestore();
    const router = useHistory();

    const [show, setShow] = useState(false);

    const selectedPlan: any = localStorage.getItem('currentPlan')
    
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onsubmit = (data) => {
        setShow(true)
        firebase.auth().createUserWithEmailAndPassword(
            data.email,
            data.password
        ).then((authUser)=>{
            localStorage.setItem('authUserId', authUser.user.uid)
            firestore.collection("users").doc(localStorage.getItem('authUserId'))
            .set({
                    uid: authUser.user.uid,
                    name: data.name,
                    phone: data.phone,
                    city: data.city,
                    email: authUser.user.email,
                    role: 'user',
                    date: new Date(),
                }).then(()=>{
                    createOrder();
                })
        }).catch(error =>{
            console.log(error.message)
        })
    }

    const createOrder =()=>{
        try {
            var data = {
                amount: selectedPlan * 100
            }
            axios.post('https://api.sellerbabu.com/orders', data).then(res=>{
                console.log(res);
                firestore.collection("order").doc(localStorage.getItem('authUserId'))
                .set({
                    orderId: res.data.order_id,
                    userId: localStorage.getItem('authUserId'),
                    amount: selectedPlan
                }).then(()=>{
                    setShow(false)
                    console.log('user and order successfully created');
                    router.push("/checkout");
                })
            })
        } catch (error) {
            console.log(error.message)
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="offset-md-3 col-md-6">
                
            <Form className={styles.formBox} onSubmit={handleSubmit(onsubmit)}>
            <Form.Group className={styles.inputBox}>
                    <Form.Label>Name<sup>*</sup></Form.Label>
                    <Form.Control
                        {...register("name", { required: true })}
                        type="text"
                        placeholder="Enter name" 
                    />
                    <p className="errorMsg">{errors.name?.type === 'required' && "Name is required"}</p>
                </Form.Group>

                <Form.Group className={styles.inputBox}>
                    <Form.Label>Phone<sup>*</sup></Form.Label>
                    <Form.Control
                        {...register("phone", { required: true })}
                        type="number"
                        placeholder="Enter phone" 
                    />
                    <p className="errorMsg">{errors.phone?.type === 'required' && "Phone is required"}</p>
                </Form.Group>

                <Form.Group className={styles.inputBox}>
                    <Form.Label>Email<sup>*</sup></Form.Label>
                    <Form.Control
                        {...register("email", { required: true })}
                        type="email"
                        placeholder="Enter email" 
                    />
                    <p className="errorMsg">{errors.email?.type === 'required' && "Email is required"}</p>
                </Form.Group>

                <Form.Group className={styles.inputBox}>
                    <Form.Label>Password<sup>*</sup></Form.Label>
                    <Form.Control 
                        {...register("password", {required: true})}
                        type="password" 
                        placeholder="Password" 
                    />
                    <p className="errorMsg">{errors.password?.type === 'required' && "Password is required"}</p>
                </Form.Group>

                <Form.Group className={styles.inputBox}>
                    <Form.Label>City<sup>*</sup></Form.Label>
                    <Form.Control
                        {...register("city", { required: true })}
                        type="text"
                        placeholder="Enter city" 
                    />
                    <p className="errorMsg">{errors.city?.type === 'required' && "City is required"}</p>
                </Form.Group>

                <div className="d-grid gap-2">
                <Button variant="danger" type="submit">
                    Next
                </Button>
                </div>
            </Form>
        </div>

        </div>

            <DialogBox show={show}>
                <div className={styles.loaderBox}>
                <Loader
                type="Puff"
                color="#00BFFF"
                height={50}
                width={50}
            />
            </div>
                </DialogBox>

            </div>
    )
}

export default Signup
