import React from 'react';
import {Modal, Button} from 'react-bootstrap';

interface IDialogProps {
    handleClose?: any;
    show: boolean;
    heading?: string;
    children: any;
    handleSubmit?: any;
    backdrop?: string;
}

const DialogBox:React.FC<IDialogProps> =({handleClose, backdrop, show, heading, children, handleSubmit})=>(
    <Modal
        show={show}
        onHide={handleClose}
        backdrop={backdrop}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        {heading && (
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {heading}
            </Modal.Title>
            </Modal.Header>
        )}
     
      <Modal.Body>
        {children}
      </Modal.Body>
      
            <Modal.Footer>
                { handleClose &&(
                    <Button onClick={handleClose}>Close</Button>
                )}

                {handleSubmit && (
                    <Button onClick={handleSubmit}>Close</Button>
                )}
                
            </Modal.Footer>
        
    </Modal>
)

export default DialogBox;