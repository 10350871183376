import React, { useEffect, useState } from 'react'
import { Form, Button, Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import DialogBox from '../../components/DialogBox/DialogBox';
import styles from './Profile.module.scss';
import { useHistory } from "react-router";


interface profileData{
    name: string,
    email: string,
    phone: string,
    education: string,
    city: string,
    pin: string,
    address: string,
}


const Profile:React.FC<profileData> = () => {

    const firestore = useFirestore();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [education, setEducation] = useState('')
    const [city, setCity] = useState('')
    const [pin, setPin] = useState('')
    const [address, setAddress] = useState('')


    const getUserDetails = async () => {
        try {
            const docRef = firestore.collection("users").doc(authId);
            const result = await docRef.get();
            if (result.exists) {
                setName(result.data().name)
                setEmail(result.data().email)
                setPhone(result.data().phone)
                setEducation(result.data().education)
                setCity(result.data().city)
                setPin(result.data().pin)
                setAddress(result.data().address)
            } else {
                console.log("user somthing went wrong");
            }
        } catch (e) {
            console.log("error", e.message);
        }
    };

    useEffect(() => {
        getUserDetails();
    }, [])

    const { register, handleSubmit, formState: { errors } } = useForm();

    const auth = useSelector((state: any) => state.firebase.auth)
    var authId = auth.uid

    const onsubmit =  (data: profileData) => {
         firestore.collection("users").doc(authId)
         .update({
            name: data.name,
            email: data.email,
            phone: data.phone,
            education: data.education,
            city: data.city,
            pin: data.pin,
            address: data.address
        })
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        history.push('/')
    }


    return (
        <div className="container">
            <div className="row">
                <div className={`offset-md-3 col-md-6 col-12`}>
                    <form className={styles.profileBox} onSubmit={handleSubmit(onsubmit)}>
                        <Row>
                            <Form.Group as={Row}>
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text"
                                defaultValue={name}
                                    {...register("name", { required: true })}
                                />
                                <p className="errorMsg">{errors.name?.type === 'required' && "Name is required"}</p>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text"
                                defaultValue={email}
                                    {...register("email", { required: true })}
                                />
                                <p className="errorMsg">{errors.email?.type === 'required' && "Email is required"}</p>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Row}>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text"
                                defaultValue={phone}
                                    {...register("phone", { required: true })}
                                />
                                <p className="errorMsg">{errors.phone?.type === 'required' && "Phone is required"}</p>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label>Education</Form.Label>
                                <Form.Control type="text"
                                defaultValue={education}
                                    {...register("education", { required: true })}
                                />
                                <p className="errorMsg">{errors.education?.type === 'required' && "Education is required"}</p>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Row}>
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text"
                                defaultValue={city}
                                    {...register("city", { required: true })}
                                />
                                <p className="errorMsg">{errors.city?.type === 'required' && "City is required"}</p>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label>Pincode</Form.Label>
                                <Form.Control type="text"
                                defaultValue={pin}
                                    {...register("pin", { required: true })}
                                />
                                <p className="errorMsg">{errors.pin?.type === 'required' && "Pin is required"}</p>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Row}>
                                <Form.Label>Address</Form.Label>
                                <Form.Control type="text"
                                defaultValue={address}
                                    {...register("address", { required: true })}
                                />
                                <p className="errorMsg">{errors.address?.type === 'required' && "Address is required"}</p>
                            </Form.Group>
                        </Row>

                        <div className={`d-grid ${styles.submitBtn}`}>
                            <Button type="submit" variant="danger">
                                Save
                            </Button>
                        </div>

                    </form>

                </div>
            </div>
            <DialogBox show={show} handleClose={handleClose}>
                <h5>Data Updated</h5>
                </DialogBox>
        </div>
    )
}

export default Profile
