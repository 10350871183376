import React from 'react'
import {Route, Redirect} from 'react-router-dom';
import {isLoaded, isEmpty} from 'react-redux-firebase';
import {useSelector} from 'react-redux';

const PrivateRoutes = ({component: Component, ...rest}) => {

    const auth = useSelector((state:any) => state.firebase.auth)

    if(!isLoaded(auth)){
        return <h2>Loading...</h2>
    }
    return !isEmpty(auth)? (
        <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
    ) : (
        <Redirect to="/login" />
    )
}

export default PrivateRoutes;