import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Header from './components/Header/Header';
import CheckOut from './pages/CheckOut/CheckOut';
import Dashboard from './pages/Dashboard/Dashboard';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import Signup from './pages/Signup/Signup';
import PrivateRoutes from './PrivateRoutes';

const App =()=> {
  return (
    <Router>
    <Header/>
    <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/join" component={Signup} />
    <PrivateRoutes exact path="/checkout" component={CheckOut} />
    <PrivateRoutes exact path="/dashboard" component={Dashboard} />
    <PrivateRoutes exact path="/profile" component={Profile} />
    </Switch>
    </Router>
  );
}

export default App;
