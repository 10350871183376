import {Navbar, Container, Nav} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { BRAND_NAME } from '../../common/constants';
import styles from './Header.module.scss';


const Header = () => {

  const firebase = useFirebase();
  const router = useHistory();

  const auth = useSelector((state:any) => state.firebase.auth)
  
  const logoutUser = async () =>{
    await firebase.logout();
    router.push("/login");
    }

    return (
    <Navbar variant="light" className={styles.headerBox}>
    <Container>
    <div className={styles.brandName}>
      <Link to="/">{BRAND_NAME} </Link>
      </div>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className={`${styles.navLink}`}>
      {/* <Link to="/">Home</Link> */}
      {/* <Link to="/dashboard">Dashboard</Link> */}
      
      {/* <Link to="/login">Login</Link> */}
      {/* <Link to="/join">Join Us</Link> */}
      {auth.email &&(
        <>
        <Link to="/profile">Profile</Link>
      <div className={styles.logOut} onClick={logoutUser}>Logout</div>
      </>
      )}
    </Nav>
    </Navbar.Collapse>

    </Container>
  </Navbar>
    )
}

export default Header
