import {Form, Button} from "react-bootstrap";
import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import styles from "./Login.module.scss";
import { BRAND_NAME } from "../../common/constants";


interface userData{
  email: string,
  password: string
}

const Login:React.FC<userData> = () => {
  const firebase = useFirebase();
  const router = useHistory();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onsubmit =  (data:userData) => {
    const userData = {
      email: data.email,
      password: data.password,
    };
     firebase.login(userData).then(()=>{
        router.push("/profile");
     })
  }


  return (
    <div className={styles.loginPage}>
      <div className="offset-md-4 col-md-4">
        <form className={styles.loginBox} onSubmit={handleSubmit(onsubmit)}>
          <div className={styles.headText}>{BRAND_NAME}</div>
          <Form.Group>
          <Form.Label>Username</Form.Label>
          <Form.Control 
            type="text"
            className={styles.inputBox}
            {...register("email", { required: true })}
           />
           <p className="errorMsg">{errors.email?.type === 'required' && "Email is required"}</p>
        </Form.Group>
          
          <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control 
            type="password"
            className={styles.inputBox}
            {...register("password", {required: true})}
           />
           <p className="errorMsg">{errors.password?.type === 'required' && "Password is required"}</p>
        </Form.Group>

        <div className="d-grid gap-2">
          <Button
            type="submit"
            className={styles.submitBtn}
          > Login</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
