import styles from './Home.module.scss';
// import { useHistory } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';

 const Home = () => {
    //  const history = useHistory();

    //  const selectedPlan= (plan) => {
    //     localStorage.setItem("currentPlan", plan)
    //     history.push('/join')
    //     console.log(localStorage.getItem('currentPlan'))
    //  }

    return (
        <div className={`container ${styles.containerBox}`}>
            <div className="row">
                <div className="offset-md-2 col-md-4 col-12">
                <Card >
                    <Card.Body>
                        <Card.Title>अखिल भारतीय पत्रकार संघ</Card.Title>
                        <Card.Text>
                        Join Akhil Bhartiya Patrakar Sangh as Member
                        </Card.Text>
                        <a href="https://rzp.io/l/abpskhabar">
                        <Button variant="primary">Pay Now 711</Button>
                        </a>
                    </Card.Body>
                </Card>
                </div>

                <div className="col-md-4 col-12">
                <Card >
                    <Card.Body>
                        <Card.Title>ABPS Khabar</Card.Title>
                        <Card.Text>
                        Join ABPS Khabar As Reporter
                        </Card.Text>
                        <a href="https://rzp.io/l/abpskhabar2">
                        <Button variant="primary">Pay Now 1599</Button>
                        </a>
                    </Card.Body>
                </Card>
                </div>

            </div>
            
            


        </div>
    )
}
export default Home;