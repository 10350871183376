import React, { useState, useEffect } from 'react'
import { useFirestore } from "react-redux-firebase";
import {useSelector} from 'react-redux';
import { useHistory } from "react-router";
import styles from './Checkout.module.scss';
import { BRAND_NAME } from '../../common/constants';
import DialogBox from '../../components/DialogBox/DialogBox';

 const CheckOut = () => {

    const firestore = useFirestore();
    const router = useHistory();
    const [show, setShow] = useState(false);
    const [orderAmount, setOrderAmount] = useState(0);
    const [orderId, setOrderId] = useState();
    const [name, setName] = useState(0);
    const [phone, setPhone] = useState(0);
    const [email, setEmail] = useState(0);
    const [city, setCity] = useState(0);

        const auth = useSelector((state:any) => state.firebase.auth)
        var authId = auth.uid

        const getOrderDetails = async () => {
            try {
              const docRef = firestore.collection("order").doc(authId);
              const result = await docRef.get();
              if (result.exists) {
                console.log(result.data().amount)
                setOrderAmount(result.data().amount)
                setOrderId(result.data().orderId)
              } else {
                console.log("order somthing went wrong");
              }
            } catch (e) {
              console.log("error", e.message);
            }
          };

          const getUserDetails = async () => {
            try {
              const docRef = firestore.collection("users").doc(authId);
              const result = await docRef.get();
              if (result.exists) {
                setName(result.data().name);
                setEmail(result.data().email);
                setPhone(result.data().phone);
                setCity(result.data().city);
              } else {
                console.log("user somthing went wrong");
              }
            } catch (e) {
              console.log("error", e.message);
            }
          };

          useEffect(()=>{
            getOrderDetails();
            getUserDetails();
          },[])

    const options = {
        key: process.env.REACT_APP_RAZOR_PAY_KEY,
        amount: orderAmount,
        name: BRAND_NAME,
        order_id: orderId,
        description: 'Joining',
        image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
        handler: function(response) {
          setShow(true)
            // console.log(response.razorpay_payment_id);
            // console.log(response.razorpay_order_id);
            // console.log(response.razorpay_signature);
            firestore.collection("orders").doc(authId)
            .update({
                paymentId: response.razorpay_payment_id,
                resOrderId: response.razorpay_order_id,
                signature: response.razorpay_signature,
                paymentStatus: 'paid'
            }).then(()=>{
              setShow(false)
                router.push("/");
            })
        },
        prefill: {
            name: name,
            contact: phone,
            email: email,
        },
        notes: {
            address: city
        },
        theme: {
            color: 'blue',
            hide_topbar: false
        }
    };

    const openCheckout = ()=> {
        var rzp1 = new (window as any).Razorpay(options);
        rzp1.on('payment.failed', function (response){
            // alert(response.error.code);
            alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
            console.log(response)
    });
        rzp1.open();
      }

      const handleClose = () => {
        setShow(false)
        router.push('/')
    }

    return (
        <div>
                {orderId && (
                    <div className={styles.containerBox}>
                    <button onClick={openCheckout} className="btn btn-primary">Pay Now</button>
                    </div>
                )}

              <DialogBox show={show} handleClose={handleClose}>
                <h5>Payment Success</h5>
                </DialogBox>
        </div>
    )
}
export default CheckOut;